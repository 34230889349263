import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import * as React from 'react';

type PropsType = {
  size?: CircularProgressProps['size'];
  color?: CircularProgressProps['color'];
  className?: string;
  loaderStyle?: React.CSSProperties;
};

export const SpinnerLoader = ({
  color,
  size,
  loaderStyle,
  className,
}: PropsType) => {
  return (
    <CircularProgress
      size={size}
      color={color}
      className={className}
      style={loaderStyle}
    />
  );
};
