import { useSelector } from 'react-redux';

import { selectUserDetails } from '~/redux/selectors/conversationsDataR';
import { RolePersonaSlug, User } from '~/utils/http/user/types';

type BooleanRoles = {
  isAdmin: boolean;
  isBotAuthor: boolean;
  hasPIIRole: boolean;
  hasDeployRole: boolean;
  hasInstaStackRole: boolean;
};

const defaultRoles: BooleanRoles = {
  isAdmin: false,
  isBotAuthor: false,
  hasPIIRole: false,
  hasDeployRole: false,
  hasInstaStackRole: false,
};

type ReturnType = User & BooleanRoles;

export const useCurrentUser = (): ReturnType => {
  const user: User = useSelector(selectUserDetails);

  const roles: BooleanRoles = {
    ...defaultRoles,
    isAdmin: user.role_persona.persona_slug === RolePersonaSlug.Admin,
    isBotAuthor: user.role_persona.persona_slug === RolePersonaSlug.BotAuthor,
    hasPIIRole: Array<RolePersonaSlug>(
      RolePersonaSlug.Analyst,
      RolePersonaSlug.Deployer,
      RolePersonaSlug.AnalystDeveloper,
      RolePersonaSlug.Admin
    ).includes(user.role_persona.persona_slug),
    hasDeployRole: Array<RolePersonaSlug>(
      RolePersonaSlug.Deployer,
      RolePersonaSlug.AnalystDeveloper,
      RolePersonaSlug.Admin
    ).includes(user.role_persona.persona_slug),
    hasInstaStackRole: Array<RolePersonaSlug>(
      RolePersonaSlug.BotAuthor,
      RolePersonaSlug.Analyst,
      RolePersonaSlug.Deployer,
      RolePersonaSlug.AnalystDeveloper,
      RolePersonaSlug.Admin
    ).includes(user.role_persona.persona_slug),
  };

  return {
    ...user,
    ...roles,
  };
};
