import { QueryServiceClient } from '~/__generated__/query-service';
import { constants } from '~/config';

import * as agent from './agent';
import * as aiPreview from './aiPreview';
import * as assets from './assets';
import { axiosClient } from './axiosClient';
import * as campaigns from './campaigns';
import * as contacts from './contacts';
import * as dashboard from './dashboard';
import * as deployment from './deployment';
import { DataSubmitMapping } from './deployment/types';
import * as inputs from './inputs';
import * as instaStack from './instaStack';
import * as skill from './skill';
import * as targetConfiguration from './targetConfiguration';
import * as training from './training';
import * as transcript from './transcript';
import * as user from './user';
import { getToken } from '../tokenHelpers';

const getFormSubmitMapping = (agent_id: number) => {
  return axiosClient.get<DataSubmitMapping[]>(`/form-submit-card/${agent_id}`);
};

export const Api = {
  agent,
  ...aiPreview,
  assets,
  campaigns,
  contacts,
  dashboard,
  deployment,
  getFormSubmitMapping,
  skill,
  targetConfiguration,
  training,
  transcript,
  user,
  inputs,
  instaStack,
};

export const QueryServiceApi = new QueryServiceClient({
  BASE: constants.query_service_url,
  WITH_CREDENTIALS: true,
  CREDENTIALS: 'include',
  TOKEN: () => Promise.resolve(getToken() ?? ''),
  HEADERS: () => {
    return Promise.resolve({
      'Content-Type': 'application/json',
    });
  },
});
