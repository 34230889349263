import { axiosClient } from '../axiosClient';
import { User } from './types';

type Options = {
  userId: number;
  body: FormData;
};

export const updateUserAvatar = ({ userId, body }: Options) => {
  return axiosClient.put<User>(`/users/${userId}/avatar`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
