import axios from 'axios';

import { LocalTokenStore } from './localTokenStore';

function serializeQueryParameters(json) {
  if (!json) return '';
  return (
    '?' +
    Object.keys(json)
      .filter((key) => json[key])
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
      )
      .join('&')
  );
}

export { TokenStore } from './tokenStore';
export class BotcoAPI {
  //
  // constructors
  //
  constructor(baseurl, tokenStore = null) {
    this.baseurl = baseurl;

    // compatibility with current API and usage
    if (tokenStore === null) {
      tokenStore = new LocalTokenStore();
    }

    this.tokenStore = tokenStore;
  }

  //
  // getters
  //
  get accessToken() {
    return this.tokenStore.accessToken;
  }
  get refreshToken() {
    return this.tokenStore.refreshToken;
  }
  get apiError() {
    return this.axiosError;
  }

  initAxios() {
    this.axiosError = null;
    this.apiData = null;
  }

  createHeader(isFileUpload = false) {
    let headers = {
      Accept: 'application/json',
      'Content-Type': !isFileUpload
        ? 'application/json'
        : 'multipart/form-data',
    };
    if (
      this.tokenStore.accessToken &&
      this.tokenStore.accessToken !== 'null' &&
      this.tokenStore.accessToken.length >= 30
    ) {
      headers.Authorization = `Bearer ${this.tokenStore.accessToken}`;
    }
    return headers;
  }

  axiosCall({ method, url, data, headers, params }) {
    axios.defaults.baseURL = this.baseurl;
    return axios({
      url,
      method: method.toLowerCase(),
      headers: Object.assign({}, this.createHeader(), headers),
      data,
      params,
    });
  }

  axiosFormUploadCall({ method, url, data, headers }) {
    axios.defaults.baseURL = this.baseurl;
    return axios({
      url,
      method: method.toLowerCase(),
      headers: Object.assign({}, this.createHeader(true), headers),
      data,
    });
  }

  //
  // callback functions
  //

  // This method should be called when a generic call succeeds
  setAxiosAPIData(data) {
    this.apiData = data;
  }

  // This method should be called when login succeeds
  setAxiosDidAuth(data) {
    this.tokenStore.accessToken = data.access_token;
    this.tokenStore.refreshToken = data.refresh_token;
    this.axiosError = null;
  }

  // This method should be called when a call fails
  setAxiosError(data) {
    this.axiosError = data;
  }

  //
  //	Java API endpoints
  //

  // Authentication endpoints

  // user login
  async login(username, password) {
    // init tokens
    this.tokenStore.initTokens();
    this.axiosError = null;

    return this.axiosCall({
      method: 'POST',
      url: '/auth/login',
      data: {
        username,
        password,
      },
    });
  }

  // user logout
  async logout() {
    this.initAxios();

    this.tokenStore.initTokens();
  }

  // refresh token
  async refreshAccessToken() {
    return this.axiosCall({
      method: 'POST',
      url: '/auth/refresh-token',
      data: {
        refresh_token: this.refreshToken,
      },
    });
  }

  // Agent & Template endpoints

  async createAgent(data, template_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents${template_id ? '/?template_id=' + template_id : ''}`,
      data,
    });
  }

  // get all agents
  async getAgents() {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: '/agents',
    });
  }

  async getPublicTemplates() {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: '/agents?is_template=1',
    });
  }

  // get one agent
  async getAgent(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}`,
    });
  }

  async isValidAgent(agent_id) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/valid`,
    });
  }

  // create converastion based on template
  async createTemplateAgent(templateId, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents?template_id=${templateId}`,
      data,
    });
  }

  async updateAgent(agent) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${agent.id}`,
      data: agent,
    });
  }

  async deleteAgent(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}`,
    });
  }

  // Analytics endpoints
  /**
   * @typedef {object} AnalyticsFilterOptions
   * @property {number} agentId
   * @property {number} deployId
   * @property {string} startDate
   * @property {string} endDate
   *
   *
   * @typedef {object} PaginatedResponse<T>
   * @property {T} content                - list of items in the page
   * @property {Object} pageable
   * @property {boolean} last
   * @property {number} totalPages
   * @property {number} totalElements
   * @property {boolean} first
   * @property {Object} sort
   * @property {number} number            - current Page.
   * @property {number} numberOfElements
   * @property {number} size
   * @property {boolean} empty
   *
   * @typedef MetricType
   * @property {number} 1 "UniqueUsers"
   * @property {number} 2 "IncomingMessages"
   * @property {number} 3 "MessagesPerSession"
   * @property {number} 4 "EngagedSessions"
   * @property {number} 5 "AvgSessionDuration"
   * @property {number} 6 "TotalMessages"
   */

  /**
   * Returns analytics overview
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<Array.<Object>>}
   */
  async getAnalyticsOverview({ agentId, deployId, startDate, endDate }) {
    this.initAxios();

    const postfix = agentId
      ? `/${agentId}${deployId ? `/${deployId}` : ''}`
      : '';

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/overview${postfix}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
      },
    });
  }

  /**
   * Returns analytics data for a specific metric
   * @param {MetricType} metric_id
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<Array.<{ date: string, result: number }>>}
   */
  async getAnalyticsMetricById(
    metric_id,
    { agentId, deployId, startDate, endDate }
  ) {
    this.initAxios();

    const postfix = agentId
      ? `/${agentId}${deployId ? `/${deployId}` : ''}`
      : '';

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/metric/${metric_id}${postfix}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
      },
    });
  }

  /**
   * Returns analytics data for all metrics
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<Array.<{ date: string, result: number }>>}
   */
  async getAllAnalyticsMetrics({ agentId, deployId, startDate, endDate }) {
    this.initAxios();

    const postfix = agentId
      ? `/${agentId}${deployId ? `/${deployId}` : ''}`
      : '';

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/metric/all${postfix}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
      },
    });
  }

  /**
   * @typedef {object} MessageAnalytics
   * @property {number} id
   * @property {number} average
   * @property {string} message
   *
   * @typedef {object} ChatbotAnalyticsType
   * @property {MessageAnalytics[]} opening_messages
   * @property {MessageAnalytics[]} exit_messages
   * @property {MessageAnalytics[]} inbound_messages
   * @property {MessageAnalytics[]} outbound_messages
   *
   * Returns chatbot conversations analytics
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<ChatbotAnalyticsType>}
   */
  async getChatbotAnalytics({
    agentId,
    deployId,
    startDate,
    endDate,
    pageSize,
    pageNumber,
    sort,
  }) {
    this.initAxios();

    const postfix = agentId
      ? `/${agentId}${deployId ? `/${deployId}` : ''}`
      : '';

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/conversations${postfix}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(pageSize && { size: pageSize }),
        ...(pageNumber && { page: pageNumber }),
        ...(sort && { sort }),
      },
    });
  }

  /**
   * @typedef {object} ConversationSession
   * @property {number} id
   * @property {number} deploy_id
   * @property {number} agent_id
   * @property {?string} first_name
   * @property {?string} last_name
   * @property {number} messages_in
   * @property {number} messages_out
   * @property {string} exit_message
   * @property {string} open_message
   * @property {Date} session_start
   * @property {Date} session_end
   *
   * Returns conversations sessions analytics
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<PaginatedResponse<ConversationSession>}
   */
  async getConversationsSessions({
    agentId,
    deployId,
    startDate,
    endDate,
    pageSize,
    pageNumber,
    sort,
  }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: '/sessions',
      params: {
        ...(agentId && { agent_id: agentId }),
        ...(deployId && { deploy_id: deployId }),
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(pageSize && { size: pageSize }),
        ...(pageNumber && { page: pageNumber }),
        ...(sort && { sort }),
      },
    });
  }

  /**
   * @typedef {object} EngagementSession
   * @property {number} engaged_sessions
   * @property {number} engaged_sessions_percent
   * @property {number} ignored_sessions
   * @property {number} ignored_sessions_percent
   * @property {number} total_sessions
   * @property {string} intent_name
   *
   * @typedef {object} ReEngagement
   * @property {number} total_sessions
   * @property {Array<EngagementSession>} sessions
   *
   * Returns analytics data for user re-engagements
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<ReEngagement>}
   */
  async getReEngagementsAnalytics({ agentId, deployId, startDate, endDate }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/reengagements/${agentId}/${deployId}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
      },
    });
  }

  /**
   * @typedef {object} IntentsEventsCount
   * @property {number} event_invokes
   * @property {number} intent_invokes
   *
   * Returns analytics data for intents and events
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<IntentsEventsCount>}
   */
  async getIntentEventsCount({ agentId, deployId, startDate, endDate }) {
    this.initAxios();

    const postfix = agentId
      ? `/${agentId}${deployId ? `/${deployId}` : ''}`
      : '';

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/count${postfix}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
      },
    });
  }

  /**
   * @typedef {object} IntentEventsMessagesAnalytics
   * @property {Array<MessageAnalytics>} event_messages
   * @property {Array<MessageAnalytics>} intent_messages
   *
   * Returns analytics data for intents and events
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<IntentEventsMessagesAnalytics>}
   */
  async getIntentEventsMessagesAnalytics({
    agentId,
    deployId,
    startDate,
    endDate,
    pageSize,
    pageNumber,
  }) {
    this.initAxios();

    const postfix = agentId
      ? `/${agentId}${deployId ? `/${deployId}` : ''}`
      : '';

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/message${postfix}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(pageSize && { size: pageSize }),
        ...(pageNumber && { page: pageNumber }),
      },
    });
  }

  /**
   * @typedef {object} FallbackIntentsAnalytics
   * @property {number} total_count
   * @property {Array<AnalyticMetricOverview>} dates
   *
   * Returns analytics data for intents fallbacks
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<FallbackIntentsAnalytics>}
   */
  async getFallbackIntentsAnalytics({ agentId, deployId, startDate, endDate }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/intents/fallback/${agentId}/${deployId}`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
      },
    });
  }

  /**
   * @typedef {object} IntentEventAnalytics
   * @property {number} id
   * @property {number} message
   * @property {number} total
   */

  /**
   * Returns analytics data for intents
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<PaginatedResponse<IntentEventAnalytics>>}
   */
  async getIntentAnalytics({
    agentId,
    deployId,
    startDate,
    endDate,
    pageSize,
    pageNumber,
  }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/intents`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(agentId && { agent_id: agentId }),
        ...(deployId && { deploy_id: deployId }),
        ...(pageSize && { size: pageSize }),
        ...(pageNumber && { page: pageNumber }),
      },
    });
  }

  /**
   * Returns all analytics data for intents
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<IntentEventAnalytics>}
   */
  async getAllIntentAnalytics({ agentId, deployId, startDate, endDate }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/intents/all`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(agentId && { agent_id: agentId }),
        ...(deployId && { deploy_id: deployId }),
      },
    });
  }

  /**
   * Returns all analytics data for events
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<IntentEventAnalytics>}
   */
  async getAllEventAnalytics({ agentId, deployId, startDate, endDate }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/events/all`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(agentId && { agent_id: agentId }),
        ...(deployId && { deploy_id: deployId }),
      },
    });
  }

  /**
   * Returns analytics data for events
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<PaginatedResponse<IntentEventAnalytics>>}
   */
  async getEventAnalytics({
    agentId,
    deployId,
    startDate,
    endDate,
    pageSize,
    pageNumber,
  }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/events`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(agentId && { agent_id: agentId }),
        ...(deployId && { deploy_id: deployId }),
        ...(pageSize && { size: pageSize }),
        ...(pageNumber && { page: pageNumber }),
      },
    });
  }

  /**
   * Returns analytics data for intents percentage
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<MessageAnalytics>}
   */
  async getIntentsPercentageAnalytics({
    agentId,
    deployId,
    startDate,
    endDate,
    limit,
  }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/intents/percentage`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(agentId && { agent_id: agentId }),
        ...(deployId && { deploy_id: deployId }),
        ...(limit && { limit: limit }),
      },
    });
  }

  /**
   * Returns analytics data for intents percentage
   * @param {AnalyticsFilterOptions} options
   * @returns {Promise<MessageAnalytics>}
   */
  async getEventsPercentageAnalytics({
    agentId,
    deployId,
    startDate,
    endDate,
    limit,
  }) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/analytics/intent-events/events/percentage`,
      params: {
        ...(startDate && { start_time: startDate }),
        ...(endDate && { end_time: endDate }),
        ...(agentId && { agent_id: agentId }),
        ...(deployId && { deploy_id: deployId }),
        ...(limit && { limit: limit }),
      },
    });
  }

  // Tenant endpoints

  // get one tenant
  async getTenant(tenantId) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/tenants/${tenantId}`,
    });
  }

  // Transcript endpoints

  // get transcripts for session
  async getTranscripts(sessionId) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/transcripts/${sessionId}`,
    });
  }
  //Get user transcripts for training UI
  async getTrainingTranscripts(deploy_id, confidence, start_time, end_time) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/transcripts/training?deploy_id=${deploy_id}?confidence=${confidence}?start_time=${start_time}?end_time=${end_time}`,
    });
  }

  // Intent endpoints

  // get all intents
  async getIntents(agent_id, params) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/intents${serializeQueryParameters(params)}`,
    });
  }

  // get one intent
  async getIntent(agent_id, intent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/intents/${intent_id}`,
    });
  }

  //create an intent
  async addIntent(data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${data.agent_id}/intents`,
      data,
    });
  }

  async updateIntent(data) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${data.agent_id}/intents/${data.id}`,
      data,
    });
  }

  async deleteIntent(agent_id, intent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/intents/${intent_id}`,
    });
  }

  // Intent Category endpoints

  // create intent category
  async addIntentCategory(agent_id, category_name) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${agent_id}/categories`,
      data: {
        agent_id,
        category_name,
        is_system: 0,
        category_order: 0,
      },
    });
  }

  // get all intent categories for agent
  async getIntentCategories(
    agent_id,
    include_intents = false,
    include_actions = true
  ) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/categories?include_intents=${include_intents}&include_actions=${include_actions}`,
    });
  }

  // get one intent category
  async getIntentCategory(agent_id, intent_category_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/categories/${intent_category_id}`,
    });
  }

  // update intent category
  async updateIntentCategory(agent_id, intent_category_id, category_name) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${agent_id}/categories/${intent_category_id}`,
      data: { category_name: category_name },
    });
  }

  // delete intent category
  async deleteIntentCategory(agent_id, intent_category_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/categories/${intent_category_id}`,
    });
  }

  // Agent Entities endpoints -> entities

  // get all Agent Entity for Agent ID
  async getAgentEntities(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/entities`,
    });
  }

  // get an Agent Entity for Entity Id
  async getAgentEntity(agent_id, entity_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/entities/${entity_id}`,
    });
  }

  // create an Agent Entity
  async createAgentEntity(payload) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${payload.agent_id}/entities`,
      data: payload,
    });
  }

  // update an Agent Entity
  async updateAgentEntity(payload) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${payload.agent_id}/entities/${payload.entity_id}`,
      data: payload,
    });
  }

  //Find all Agent Entity Items by entity ID
  async getAgentEntityItems(agent_id, entity_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/entities/${entity_id}/items`,
    });
  }

  // create an Agent Entity Item
  async createAgentEntityItem(agent_id, entity_id, agent_entity_items) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${agent_id}/entities/${entity_id}/items`,
      data: agent_entity_items,
    });
  }

  //update an Agent Entity Item
  async updateAgentEntityItem(
    agent_id,
    entity_id,
    entity_item_id,
    item_name,
    synonym_parent_id
  ) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${agent_id}/entities/${entity_id}/items/${entity_item_id}`,
      data: {
        item_name,
        synonym_parent_id,
      },
    });
  }

  //Delete an Agent Entity Item
  async deleteAgentEntity(agent_id, entity_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/entities/${entity_id}`,
    });
  }

  //Delete an Agent Entity Item
  async deleteAgentEntityItem(agent_id, entity_id, entity_item_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/entities/${entity_id}/items/${entity_item_id}`,
    });
  }

  // Deployments Facebook-menu endpoints -> entities

  //Get all Facebook Menu Items by Agent ID
  async getDeploymentFacebookMenus(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/deployments/facebook-menus?agent_id=${agent_id}`,
    });
  }

  //Create a new Facebook Menu
  async createDeploymentFacebookMenu(fbMenu) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/deployments/facebook-menus`,
      data: fbMenu,
    });
  }

  //Create a new Facebook Menu Item
  async createDeploymentFacebookMenuItem(fbMenuItem) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/deployments/facebook-menus/${fbMenuItem.fb_menu_id}`,
      data: fbMenuItem,
    });
  }

  //Update a new Facebook Menu
  async updateDeploymentFacebookMenu(
    fb_menu_id,
    agent_id,
    deploy_id,
    menu_locale
  ) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/deployments/facebook-menus/${fb_menu_id}`,
      data: {
        agent_id,
        deploy_id,
        menu_locale,
      },
    });
  }

  //Update a new Facebook Menu Item
  async updateDeploymentFacebookMenuItem(fbMenuItem) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/deployments/facebook-menus/${fbMenuItem.fb_menu_id}/facebook-menu-item/${fbMenuItem.fb_menu_item_id}`,
      data: fbMenuItem,
    });
  }

  //Delete a new Facebook Menu
  async deleteDeploymentFacebookMenu(fb_menu_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/deployments/facebook-menus/${fb_menu_id}`,
    });
  }

  //Delete a new Facebook Menu Item
  async deleteDeploymentFacebookMenuItem({ fb_menu_id, fb_menu_item_id }) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/deployments/facebook-menus/${fb_menu_id}/facebook-menu-item/${fb_menu_item_id}`,
    });
  }

  async deleteDeploymentFacebookMenuAllItems(fb_menu_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/deployments/facebook-menus/${fb_menu_id}/facebook-menu-item/all`,
    });
  }

  // Find the first deployment facebook greeting record for a deploy ID.
  async getFirstDeploymentFacebookGreeting(deploy_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/deployments/${deploy_id}/facebook-greetings`,
    });
  }

  // Create a new deployment facebook greeting or update it if this already exists
  async createDeploymentFacebookGreeting(data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/deployments/${data.deploy_id}/facebook-greetings`,
      data,
    });
  }

  // create the agent form v2
  async createAgentFormV2(agent_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/v2/agents/${agent_id}/forms`,
      data,
    });
  }

  // get the agent form v2
  async getAgentFormV2(agent_id, form_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/v2/agents/${agent_id}/forms/${form_id}`,
    });
  }

  // update the agent form v2
  async updateAgentFormV2(agent_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/v2/agents/${agent_id}/forms/${data.id}`,
      data,
    });
  }

  async getFormConnections(agent_id, form_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/v2/agents/${agent_id}/forms/${form_id}/connections`,
    });
  }

  // forms endpoints -> forms

  // create the agent form
  async createAgentForm(data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${data.agent_id}/forms`,
      data,
    });
  }

  // list the agent forms
  async getAgentForms(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/forms`,
    });
  }

  // get agent from
  async getAgentForm(agent_id, form_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/forms/${form_id}`,
    });
  }

  // delete the agent form
  async deleteAgentForm(agent_id, form_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/forms/${form_id}`,
    });
  }

  // update the agent form
  async updateAgentForm(data) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${data.agent_id}/forms/${data.form_id}`,
      data,
    });
  }

  // get the agent form blocks data
  async getAgentFormBlocks(agent_id, form_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/forms/${form_id}/blocks`,
    });
  }

  // create the agent form block
  async createAgentFormBlock(agent_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${agent_id}/forms/${data.form_id}/blocks`,
      data,
    });
  }

  // delete the agent form block
  async deleteAgentFormBlock(agent_id, form_id, form_block_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/forms/${form_id}/blocks/${form_block_id}`,
    });
  }

  // get agent form block queestions
  async getAgentFormBlockQuestions(agent_id, form_id, form_block_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/forms/${form_id}/blocks/${form_block_id}/questions`,
    });
  }

  // create agent from block question
  async updateAgentFormBlockQuestions(agent_id, form_id, form_block_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${agent_id}/forms/${form_id}/blocks/${form_block_id}/questions`,
      data,
    });
  }

  // update the agent form block queestion
  async updateAgentFormBlockQuestion(agent_id, form_id, form_block_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/forms/${form_id}/blocks/${form_block_id}/questions/${data.question_id}`,
      data,
    });
  }

  // update question branch
  async updateAgentFormBlockQuestionBranch(
    agent_id,
    form_id,
    form_block_id,
    question_id,
    branch
  ) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${agent_id}/forms/${form_id}/blocks/${form_block_id}/questions/${question_id}/branch/${branch.branch_id}`,
      data: branch,
    });
  }

  // delete question branch
  async deleteAgentFormBlockQuestionBranch(
    agent_id,
    form_id,
    form_block_id,
    question_id,
    branch_id
  ) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/forms/${form_id}/blocks/${form_block_id}/questions/${question_id}/branch/${branch_id}`,
    });
  }

  async deleteAgentFormBlockQuestion(
    agent_id,
    form_id,
    form_block_id,
    question_id
  ) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/forms/${form_id}/blocks/${form_block_id}/questions/${question_id}`,
    });
  }

  //get all forms info
  async getForms(agent_id, deploy_id, start_time, end_time, fields) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/forms?deploy_id=${deploy_id}&start_time=${start_time}&end_time=${end_time}&fields=${fields}`,
    });
  }

  //Users endpoints -> users

  //"Find a list Users
  async getUsers() {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/users`,
    });
  }

  //Find an User by ID
  async getUserById(user_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/users/${user_id}`,
    });
  }

  //Create a new User
  async createUser(user) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/users`,
      data: user,
    });
  }

  //Delete an User
  async deleteUser(user_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/users/${user_id}`,
    });
  }

  //Update an User
  async updateUser(user) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/users/${user.user_id}`,
      data: user,
    });
  }

  //Reset password for an User
  async resetPassword(user_id, new_password) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/users/${user_id}/change-password`,
      data: {
        new_password,
      },
    });
  }

  //Update user's avatar
  async updateUserAvatar(user_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/users/${user_id}/avatar`,
      data,
    });
  }

  //Find User's Roles by UserId
  async getUserRoles(user_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/users/${user_id}/roles`,
    });
  }

  //Add Roles to an User
  async createUserRoles(user_id, role_ids) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/users/${user_id}/roles`,
      data: {
        role_id: role_ids,
      },
    });
  }

  //Delete Roles to an User
  async deleteUserRoles(user_id, role_ids) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/users/${user_id}/roles`,
      data: {
        role_id: role_ids,
      },
    });
  }

  //accounts endpoints -> accounts

  //Find Account By Id
  async getMyAccount() {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/me/account`,
    });
  }

  // update user avatar
  async updateMyAvatar(data) {
    this.initAxios();
    return this.axiosFormUploadCall({
      method: 'PUT',
      url: `/me/avatar`,
      data,
    });
  }

  //Me endpoints -> me

  //Find the logged user data.
  async getMe() {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/me`,
    });
  }
  //Update the logged user data
  async updateMe(user) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/me`,
      data: user,
    });
  }

  //Reset password the logged user
  async resetPasswordMe(new_password) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/me/change-password`,
      data: {
        new_password,
      },
    });
  }

  //Aws S3 endpoints -> assets

  //Upload An Image or video to Amazon S3
  async uploadFile(data) {
    this.initAxios();
    return this.axiosFormUploadCall({
      method: 'POST',
      url: `/assets/upload`,
      data,
    });
  }

  // get all deployments for the account
  async getDeployments(runtime_tenant_id = false) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/deployments?runtime_tenant_id=${runtime_tenant_id}`,
    });
  }

  async getAgentDeployments(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/deployments?agent_id=${agent_id}`,
    });
  }

  // get all deployments for the account
  async deployConversation(data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/deployments`,
      data,
    });
  }

  // redeploy a conversation
  async reDeployConversation(deploy_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/deployments/${deploy_id}`,
      data,
    });
  }

  // get deployed conversation
  async getDeploymentDetails(deploy_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/deployments/${deploy_id}`,
    });
  }

  // start a deployment
  async startDeployment(deploy_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/deployments/${deploy_id}/start`,
    });
  }

  // stop a deployment
  async stopDeployment(deploy_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/deployments/${deploy_id}/stop`,
    });
  }

  // stop a deployment
  async deleteDeployment(deploy_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/deployments/${deploy_id}/delete`,
    });
  }

  // new delete deployment endpoint
  async newDeleteDeployment(deploy_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/deployments/${deploy_id}`,
    });
  }

  // deploy with deploy_id existing
  async newDeployExisting(deploy_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/deployments/${deploy_id}/deploy`,
      data,
    });
  }

  async getDeployAttributesByKey(api_key) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/deployments/bot/attributes?api_key=${api_key}`,
    });
  }

  async getDeployAttributesById(deploy_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/deployments/${deploy_id}/attributes`,
    });
  }

  //get dashboard forms CSV data
  async getDashFormsCSVData({ deploy_id, form_id, start, endDate }) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/formdata/${form_id}/csv?deploy_id=${deploy_id}&start_time=${start}&end_time=${endDate}`,
    });
  }

  //dashboard transcripts csv
  async getDashTranscriptCSVData({ start, endDate, deploy_id }) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/sessions/transcripts?start_time=${start}&deploy_id=${deploy_id}&end_time=${endDate}`,
    });
  }

  async exportDashTranscriptCSVData({ start, endDate, deploy_id }) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/sessions/transcripts/csv?start_time=${start}&deploy_id=${deploy_id}&end_time=${endDate}`,
    });
  }

  //get training utterances
  async getTrainingUtterances({
    start_time,
    agent_id,
    page,
    size,
    confidence,
  }) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: '/trainings',
      params: {
        start_time,
        agent_id,
        page,
        size,
        confidence,
      },
    });
  }

  //get single training session
  async getSingleTrainingUtterance({ sessionId, confidence }) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/trainings/${sessionId}/sessions?confidence=${confidence}`,
    });
  }

  async getAgentInputs(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/inputs`,
    });
  }

  async getAgentInputsHistory(agent_id, params) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/inputs/history${serializeQueryParameters(
        params
      )}`,
    });
  }

  async getIntentInputs(agent_id, intent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/intents/${intent_id}/inputs`,
    });
  }

  async deleteIntentInput(agent_id, intent_id, input_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/intents/${intent_id}/inputs/${input_id}`,
    });
  }

  //create an intent input for training
  async createIntentInputTranscript({
    agent_id,
    input_value,
    intent_id,
    deploy_id,
    training_log_id,
  }) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${agent_id}/intents/${intent_id}/inputs/transcript`,
      data: { input_value, intent_id, deploy_id, training_log_id },
    });
  }

  async createIntentInputs(agent_id, intent_id, input_values) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${agent_id}/intents/${intent_id}/inputs`,
      data: input_values,
    });
  }

  async updateIntentInputs(agent_id, intent_id, input_values) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${agent_id}/intents/${intent_id}/inputs`,
      data: input_values,
    });
  }

  //delete button
  async removeActionButton(agent_id, intent_id, action_id, choice_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'DELETE',
      url: `/agents/${agent_id}/intents/${intent_id}/actions/${action_id}/action-choices/${choice_id}`,
    });
  }

  async getConnectionsByIntent(agent_id, intent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/intents/${intent_id}/connections`,
    });
  }

  // Intent Action endpoints

  // getCardsByIntent = getIntentActions
  async getCardsByIntent(agent_id, intent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/intents/${intent_id}/actions`,
    });
  }

  // get one intent action
  async getIntentAction(agent_id, intent_id, action_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/intents/${intent_id}/actions/${action_id}`,
    });
  }

  // update one intent action
  async updateIntentAction(agent_id, intent_id, action_id, body) {
    this.initAxios();
    return this.axiosCall({
      method: 'PUT',
      url: `/agents/${agent_id}/intents/${intent_id}/actions/${action_id}`,
      data: body,
    });
  }

  // save intent card
  async saveIntentCards(agent_id, intent_id, data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${agent_id}/intents/${intent_id}/actions`,
      data,
    });
  }

  // get agent attributes
  async getAgentAttributes(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/attributes`,
    });
  }

  // create agent attribute
  async createAgentAttribute(data) {
    this.initAxios();
    return this.axiosCall({
      method: 'POST',
      url: `/agents/${data.agent_id}/attributes`,
      data,
    });
  }

  // get form analytics

  // get user forms or agent's forms
  async getUserForms(agent_id = null) {
    if (agent_id < 1) {
      return;
    }
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/forms`,
    });
  }

  async getDashboardFormResponses(
    agent_id,
    deploy_id,
    form_id,
    start_date,
    end_date
  ) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `analytics/forms/responses/${agent_id}/${deploy_id}/${form_id}?start_time=${start_date}&end_time=${end_date}`,
    });
  }
  async getDashboardFormResponseDetails(
    agent_id,
    deploy_id,
    form_id,
    start_date,
    end_date
  ) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `analytics/forms/responses/details/${agent_id}/${deploy_id}/${form_id}?start_time=${start_date}&end_time=${end_date}`,
    });
  }

  async getDashboardFormEngagements(
    agent_id,
    deploy_id,
    form_id,
    start_date,
    end_date
  ) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `analytics/forms/engagements/${agent_id}/${deploy_id}/${form_id}?start_time=${start_date}&end_time=${end_date}`,
    });
  }

  async getDashboardFormMetrics(
    agent_id,
    deploy_id,
    form_id,
    start_date,
    end_date
  ) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `analytics/forms/metrics/${agent_id}/${deploy_id}/${form_id}?start_time=${start_date}&end_time=${end_date}`,
    });
  }

  async getLatestWidgetInfo() {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: '/webchat-widget-versions/latest',
    });
  }

  async getAgentApis(agent_id) {
    this.initAxios();
    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/apis`,
    });
  }

  async getAgentApiDetails(agent_id, api_id, params) {
    this.initAxios();

    return this.axiosCall({
      method: 'GET',
      url: `/agents/${agent_id}/apis/${api_id}${serializeQueryParameters(
        params
      )}`,
    });
  }

  async exchangeFacebookToken(data) {
    this.initAxios();

    return this.axiosCall({
      method: 'POST',
      url: `/deployments/exchange-fb-token`,
      data,
    });
  }
}
