import { Honeybadger } from '@honeybadger-io/react';
import LogRocket from 'logrocket';
import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGetAgent } from '~/containers/Conversations/ConversationPreview/useGetAgent';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useQueryParams } from '~/hooks/useQueryParams';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { getAllAgents, getMe } from '~/redux/actions/conversationDataR';
import { resetReduxStore } from '~/redux/actions/conversationPreview';
import { getCurrentRouteRecord, removeTokens } from '~/utils';
import { getDefaultChatbot } from '~/utils/storageHelpers';
import * as yup from '~/utils/yup';

type State = {
  openModal: boolean;
  showComponent: boolean;
  loading: boolean;
};

const initialState: Readonly<State> = {
  openModal: false,
  showComponent: false,
  loading: true,
};

export const useLayout = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<State>(initialState);
  const history = useHistory();
  const queryParams = useQueryParams();

  const { selectedAgent, setSelectChatbot } = useSelectedChatbot();

  const currentUser = useCurrentUser();

  const defaultChatbotId = React.useMemo(() => {
    const { match } = getCurrentRouteRecord(history.location.pathname);

    let urlAgentId: number | null | undefined = null;

    if (match) {
      const conversationId =
        'conversation_id' in match.params
          ? match?.params.conversation_id
          : null;

      const agentId = 'agentId' in match.params ? match?.params.agentId : null;

      urlAgentId = yup
        .safeNumber()
        .nullable()
        .validateSync(conversationId || agentId || queryParams.get('agentId'));
    }

    return urlAgentId || getDefaultChatbot(currentUser.account_id);
  }, [history.location.pathname, currentUser.account_id, queryParams]);

  const { isLoading: isLoadingAgent } = useGetAgent({
    agentId: Number(defaultChatbotId),
    enabled: Boolean(
      defaultChatbotId &&
        (!selectedAgent || selectedAgent.id !== Number(defaultChatbotId))
    ),
    retry: false,
    onSuccess: (agent) => {
      if (!agent) return;
      setSelectChatbot(agent);
    },
  });

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    debounce: 500,
    onIdle: () => {
      setState((state) => ({ ...state, openModal: true }));
    },
  });

  React.useEffect(() => {
    const setup = async () => {
      try {
        setState((state) => ({ ...state, showComponent: true }));
        dispatch(getMe());
        dispatch(getAllAgents());
      } catch {
        const url = new URL(window.location.href);
        history.push(
          url.pathname !== '/'
            ? `/?redirectUrl=${url.pathname}${url.search}`
            : '/'
        );
      }
    };

    setup();
  }, [history, dispatch]);

  React.useEffect(() => {
    if (!state.showComponent || !currentUser?.user_id || !state.loading) return;

    const user = {
      userId: currentUser.user_id,
      accountId: currentUser.account_id,
      email: currentUser.email,
      userName: `${currentUser.first_name} ${currentUser.last_name}`,
      userAvatar: currentUser.user_avatar ?? '',
    };

    Honeybadger.setContext(user);
    LogRocket.identify(`${user.userId}`, user);

    setState((state) => ({ ...state, loading: false }));
  }, [state.showComponent, state.loading, currentUser, history]);

  const logout = () => {
    setState(initialState);
    removeTokens();
    window.sessionStorage.clear();
    dispatch(resetReduxStore());
    history.push('/');
  };

  return {
    showComponent: !isLoadingAgent && !state.loading && state.showComponent,
    openIdleDialog: state.openModal,
    logout,
  };
};
