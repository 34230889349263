import Axios from 'axios';
import { toast, CommonOptions } from 'react-toastify';

import { removeTokens } from './tokenHelpers';
import { historyAction } from '../components/historyAction';

type ToastType = 'info' | 'warn' | 'success' | 'error';

export const showToast = (
  type: ToastType,
  message = 'Something went wrong!',
  customConfigs: CommonOptions = {}
) => {
  let config: CommonOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
    closeButton: false,
    closeOnClick: true,
    autoClose: 2500,
  };

  if (customConfigs) {
    config = {
      ...config,
      ...customConfigs,
    };
  }
  switch (type) {
    case 'info':
      toast.info(message, config);
      break;
    case 'warn':
      toast.warn(message, config);
      break;
    case 'success':
      toast.success(message, config);
      break;
    case 'error':
      toast.error(message, config);
      break;
    default:
      toast.warn(message, config);
      break;
  }
};

export const handleErrors = (err: Error | unknown, message?: string) => {
  const { responseMessage, status } = (() => {
    if (!Axios.isAxiosError(err))
      return {
        status: 0,
        responseMessage: message || 'Something went wrong.',
      };

    return {
      status: err.response?.status || 0,
      responseMessage:
        (err.response?.data as any)?.message ||
        message ||
        'Something went wrong.',
    };
  })();

  switch (status) {
    case 400:
      showToast('error', responseMessage || 'Something went wrong.');
      break;
    case 401:
      removeTokens();
      window.location.reload();
      break;
    case 403:
      historyAction.push('/Conversations');
      break;
    case 404:
      showToast('error', responseMessage || 'Something went wrong.');
      break;
    case 500:
      if (responseMessage) showToast('error', responseMessage);
      break;
    case 501:
      showToast('error', 'Incorrect query or invalid column value');
      break;
    case 502:
      showToast(
        'error',
        responseMessage || 'Error code 502: Something went wrong.'
      );
      break;
    case 409:
      showToast('error', responseMessage || 'Duplicate entry');
      break;
    case 410:
      showToast(
        'error',
        'Unable to delete intent as it is being used in the application!'
      );
      break;
    default:
      showToast('error', responseMessage || 'Something went wrong.');
      break;
  }
};
