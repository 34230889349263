import { components } from '~/__generated__/botco-api-schema';

export const RoleEnum = {
  Administrator: 1,
  BotAuthor: 2,
  Deploy: 3,
  BotPii: 4,
  InstaStack: 5,
} as const;

export type Role = {
  role_id: EnumValueType<typeof RoleEnum>;
  role_name: string;
};

export const RolePersonaSlug = {
  Admin: 'administrator',
  Analyst: 'analyst',
  AnalystDeveloper: 'analyst-deployer',
  Deployer: 'deployer',
  BotAuthor: 'bot_author',
} as const;

// eslint-disable-next-line no-redeclare
export type RolePersonaSlug = EnumValueType<typeof RolePersonaSlug>;

export type RolePersonal = {
  persona_name: string;
  persona_slug: RolePersonaSlug;
  role_persona_id: number;
};

export type User = Omit<Required<components['schemas']['User']>, 'roles'> & {
  role_persona: RolePersonal;
};
