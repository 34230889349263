import Axios from 'axios';

import { constants } from '~/config';

import { getToken } from '../tokenHelpers';

export const axiosClient = Axios.create({
  baseURL: constants.api_url,
});

axiosClient.interceptors.request.use((request) => {
  const token = getToken();
  if (token) {
    request.headers!['Authorization'] = `Bearer ${token}`;
  }

  return request;
});
