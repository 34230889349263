import 'react-image-crop/dist/ReactCrop.css';

import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { base64ToBlob } from 'base64-blob';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BotcoAPI } from '@botco/api';
import { Button, TextInput, Typography } from '@botco/library';
import { constants } from '~/config';
import { routes } from '~/constants/routes';
import { getAllAgents } from '~/redux/actions/conversationDataR';
import { handleErrors, showToast } from '~/utils';

const newApiCall = new BotcoAPI(constants.api_url);

class ModalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversation_title: '',
      avatar: '',
      avatarPreview: '',
      showSpinner: false,
      croppedImage: '',
      disableSave: false,
      titleError: false,
      crop: {
        unit: 'px',
        width: 200,
        height: 200,
        maxWidth: 500,
        aspect: 1 / 1,
      },
      croppedImageUrl: '',
    };
  }

  handleChange = (event) => {
    this.setState(
      {
        conversation_title: event.target.value,
      },
      () => this.checkConversationTitle()
    );
  };

  validateForm() {
    return (
      this.state.conversation_title.trim().length > 0 &&
      this.state.disableSave === false
    );
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ showSpinner: true, disableSave: true });
    const body = {
      avatar: this.state.croppedImageUrl,
      agent_name: this.state.conversation_title.trim(),
      is_template: false,
      is_public: null,
      welcome_intent_id: null,
      parent_agent_id: null,
    };

    newApiCall
      .createAgent(body)
      .then(({ data }) => {
        document.getElementById('myInput').value = '';
        this.setState(
          {
            agent_id: data.id,
            showSpinner: false,
            disableSave: false,
            croppedImageUrl: '',
            croppedImage: '',
          },
          () => {
            this.props.getAllAgents();
            this.props.closed();
            this.props.history.push(routes.chatbotDetails.get(data.id));
          }
        );
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          showToast('error', err?.response?.data?.message);
        }

        this.setState({
          showSpinner: false,
          disableSave: false,
        });
        document.getElementById('myInput').value = '';
      });
  };

  checkConversationTitle = () => {
    if (this.state.conversation_title === '' || !this.state.conversation_title)
      this.setState({
        titleError: true,
      });
    else
      this.setState({
        titleError: false,
      });
  };

  onSelectFile = (e) => {
    this.setState({
      crop: {
        unit: 'px',
        width: 200,
        height: 200,
        maxWidth: 500,
        aspect: 1 / 1,
      },
    });
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      (e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'image/bmp')
    ) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          avatarPreview: reader.result,
          selectedFile: e.target.files[0],
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      showToast('error', 'Invalid format');
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    if (crop.width >= 200 && crop.width <= 500) this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl, disableSave: false });
    }
  }

  async getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/jpeg');

    // return base64Image;
    const body = new FormData();
    const avatarBlob = await base64ToBlob(base64Image);
    body.append('file', avatarBlob, this.state.selectedFile?.name);

    try {
      const response = await newApiCall.uploadFile(body);
      const { location } = response.data;
      return location;
    } catch (err) {
      handleErrors(err);
      return null;
    }
  }

  closeCreateConvModal = () => {
    document.getElementById('myInput').value = '';
    this.setState(
      {
        croppedImageUrl: '',
        avatarPreview: '',
        disableSave: false,
        selectedFile: undefined,
      },
      () => this.props.closed()
    );
  };

  render() {
    const { fullScreen } = this.props;
    const { crop } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        open={this.props.modalOpen}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              this.props.useThisTemplateFlag
                ? this.props.useThisTemplate(
                    this.state.croppedImageUrl,
                    this.state.conversation_title.trim(),
                    e
                  )
                : this.handleSubmit(e);
            }}
            encType="multipart/form-data"
          >
            <Typography
              align="center"
              variant="h6"
              fontWeight={600}
              color="primary"
            >
              Create Chatbot!
            </Typography>
            <Box mb={2}>
              <Typography fontWeight={600} variant="body2">
                Chatbot name
              </Typography>
              <TextInput
                autoFocus
                fullWidth
                placeholder="Agent name"
                onBlur={this.checkConversationTitle}
                value={this.state.conversation_title}
                onChange={this.handleChange}
                helperText="e.g. NIKE"
              />
            </Box>
            <Box mt={2}>
              <Typography fontWeight={600} variant="body2">
                Upload chatbot avatar
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                my="16px"
                style={{ gap: 16 }}
              >
                <input
                  id="myInput"
                  type="file"
                  ref={(ref) => (this.myInput = ref)}
                  style={{ display: 'none' }}
                  onChange={this.onSelectFile}
                />

                <Avatar src={this.state.avatarPreview} />
                <IconButton size="small" onClick={() => this.myInput.click()}>
                  <PublishIcon fontSize="large" color="primary" />
                </IconButton>
                {this.state.avatarPreview === '' && (
                  <Typography variant="caption" color="textSecondary">
                    (Images should be 200px x 200px,ideally uploaded as a
                    square.)
                  </Typography>
                )}
              </Box>
            </Box>

            {this.state.avatarPreview !== '' && (
              <ReactCrop
                src={this.state.avatarPreview}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            <Box
              display="flex"
              justifyContent="center"
              mt={2}
              style={{ gap: 16 }}
            >
              <Button
                variant="outlined"
                onClick={() => this.closeCreateConvModal()}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={!this.validateForm()}>
                {this.state.showSpinner ? (
                  <CircularProgress size={16} />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

ModalSummary.propTypes = {
  fullScreen: PropTypes.bool,
};

const mapActionsToProps = {
  getAllAgents,
};

const connector = connect(null, mapActionsToProps);

// eslint-disable-next-line import/no-default-export
export default connector(withRouter(ModalSummary));
