import { nanoid } from 'nanoid';

import { ChoiceTypes } from '~/constants';
import { CardTypes } from '~/constants/general';
import { createIntentData, reorderList } from '~/utils';

import * as actionTypes from '../types/conversationDataR';

const initialState = Object.freeze({
  errors: [],
  categoriesData: [],
  intentCardsData: [],
  removedIntents: [],
  allIntents: [],
  saveAllCardsButtonState: true,
  allBlocks: [],
  allDeploymentsByAccount: [],
  allAgents: [],
  loader: false,
  loggedInUserDetails: {
    accountName: '',
    userName: '',
    role_persona: {
      persona_slug: '',
    },
  },
  conversationSaving: false,
  savedCardsStatus: true,
  allAttributes: [],
  sourceIntents: [],
  sourceForms: [],
  destinationIntents: [],
  destinationForms: [],
  hasOutdatedDeployments: false,
  formsData: [],
  allAPIs: [],
  currentSlide: -1,
});

export const conversationDataRReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CATEGORY_DATA: {
      const cat = action.payload;
      const categoriesData = [...state.categoriesData, cat];
      return {
        ...state,
        categoriesData: [...categoriesData],
      };
    }
    case actionTypes.GET_ME_DATA: {
      return {
        ...state,
        loggedInUserDetails: action.payload,
      };
    }
    case actionTypes.GET_ALL_AGENTS: {
      return {
        ...state,
        allAgents: action.payload,
      };
    }
    case actionTypes.LIST_ALL_DEPLOYMENTS: {
      const list = action.payload;
      const hasOutdated = action.payload.find((item) => item.outdated);
      return {
        ...state,
        allDeploymentsByAccount: list,
        hasOutdatedDeployments: hasOutdated,
      };
    }
    case actionTypes.LOGIN_USER: {
      return {
        ...state,
        loggedIn: true,
      };
    }
    case actionTypes.LOGOUT_USER: {
      return {
        ...state,
        loggedIn: false,
      };
    }
    case actionTypes.HANDLE_ERROR: {
      return {
        ...state,
        errors: action.errors,
        intentCardsData: action.receivedCards,
      };
    }
    case actionTypes.CHANGE_INTENT_CATEGORY: {
      const { oldCategoryId, intent } = action.payload;

      if (!intent) return state;

      const updatedCategories = state.categoriesData.map((c) => {
        if (c.id === oldCategoryId) {
          const intents = c.intents.filter((i) => i.id !== intent.id);
          return { ...c, intents };
        }
        if (c.id === intent.category_id) {
          return {
            ...c,
            intents: [...c.intents, intent],
          };
        }
        return c;
      });

      return {
        ...state,
        categoriesData: updatedCategories,
        allIntents: state.allIntents.map((i) =>
          i.id === intent.id ? intent : i
        ),
      };
    }
    case actionTypes.GET_INTENTS_BY_AGENT_R: {
      const intents =
        action.payload.data === undefined ? [] : action.payload.data;
      const newCards = createIntentData(intents);
      let allIntents = [];
      action.payload.intent_names_result.forEach((item) => {
        allIntents = [...allIntents, ...item.intents];
      });
      const categoriesData = action.payload.intent_names_result.map(
        (category) => ({
          ...category,
          forms: state.formsData.filter(
            (form) => form.category_id === category.id
          ),
        })
      );
      return {
        ...state,
        removedIntents: [],
        intentCardsData: newCards,
        categoriesData,
        allIntents,
        savedCardsStatus: true,
      };
    }
    case actionTypes.UPDATE_ALL_INTENTS: {
      const intent = action.payload;
      let allIntents = [...state.allIntents];
      const categoriesData = [...state.categoriesData];
      const cat_ind = categoriesData.findIndex(
        (item) => item.id === intent.category_id
      );

      const block = {
        value: action.payload.id,
        label: action.payload.intent_name,
      };

      allIntents = [...allIntents, intent];

      if (categoriesData[cat_ind]) {
        let newcategory = [...categoriesData[cat_ind].intents];
        newcategory = [...newcategory, intent];
        categoriesData[cat_ind].intents = [...newcategory];
      }
      return {
        ...state,
        allIntents: [...allIntents],
        categoriesData: [...categoriesData],
        allBlocks: [block, ...state.allBlocks],
      };
    }
    case actionTypes.UPDATE_ALL_INTENTS_WITH_SEQUENCE: {
      const intent = action.payload;
      const category = action.category;
      let allIntents = [...state.allIntents];
      category.intents.push(intent);
      let categoriesData = [...state.categoriesData];
      const block = {
        value: action.payload.intent_id,
        label: action.payload.intent_name,
      };
      categoriesData = [...categoriesData, category];
      allIntents = [...allIntents, intent];
      return {
        ...state,
        allIntents: [...allIntents],
        categoriesData: [...categoriesData],
        allBlocks: [block, ...state.allBlocks],
      };
    }
    case actionTypes.ADD_FORM_ITEM: {
      return {
        ...state,
        formsData: [...state.formsData, action.payload],
      };
    }
    case actionTypes.DELETE_FORM_R: {
      const forms = state.formsData.filter(
        (item) => item.value !== action.form_id
      );

      const categoriesData = state.categoriesData.map((category) => {
        return {
          ...category,
          forms: forms.filter((form) => form.category_id === category.id),
        };
      });

      return {
        ...state,
        formsData: forms,
        categoriesData,
      };
    }

    case actionTypes.DELETE_INTENT: {
      const { intent_id } = action.payload;

      const allIntents = [...state.allIntents];
      const category_id = allIntents.find(
        (item) => item.id === intent_id
      ).category_id;
      const intent_ind = allIntents.findIndex((item) => item.id === intent_id);
      const intents = [
        ...allIntents.slice(0, intent_ind),
        ...allIntents.slice(intent_ind + 1),
      ];
      const categoriesData = [...state.categoriesData];
      const cat_ind = categoriesData.findIndex(
        (item) => item.id === category_id
      );

      const cat_intents = categoriesData
        .find((item) => item.id === category_id)
        .intents.filter((item) => item.id !== intent_id);

      const category = { ...categoriesData[cat_ind], intents: cat_intents };
      categoriesData.splice(cat_ind, 1, category);

      return {
        ...state,
        categoriesData: [...categoriesData],
        allIntents: [...intents],
        savedCardsStatus: true,
        allBlocks: state.allBlocks.filter((block) => block.value !== intent_id),
      };
    }
    case actionTypes.SET_CURRENT_FORM_R: {
      return {
        ...state,
        currentForm: action.payload,
      };
    }
    case actionTypes.GET_FORMS_BY_AGENT_R: {
      const data = action.payload.map((item) => ({
        id: item.form_id,
        value: item.form_id,
        label: item.form_name,
        ...item,
      }));

      const categoriesData = [...state.categoriesData].map((category) => {
        return {
          ...category,
          forms: data.filter((item) => item.category_id === category.id),
        };
      });

      return {
        ...state,
        formsData: data,
        categoriesData,
      };
    }
    case actionTypes.GET_CARDS_BY_INTENT_R: {
      const intents1 = action.payload;
      const newCards1 = createIntentData(intents1);
      return {
        ...state,
        intentCardsData: newCards1,
        currentForm: null,
        errors: [],
        removedIntents: [],
        savedCardsStatus: true,
      };
    }
    case actionTypes.GET_CONNECTIONS_BY_INTENT: {
      const {
        sourceIntents,
        sourceForms,
        destinationIntents,
        destinationForms,
      } = action.payload;
      return {
        ...state,
        sourceIntents,
        sourceForms,
        destinationIntents,
        destinationForms,
      };
    }
    case actionTypes.ADD_INTENT_CARD_R: {
      const action_order = state.intentCardsData.length + 1;
      const newCard = { ...action.payload, action_order, uuid: nanoid() };
      return {
        ...state,
        intentCardsData: [...state.intentCardsData, newCard],
        savedCardsStatus: false,
      };
    }
    case actionTypes.INSERT_INTENT_CARD_R: {
      const intentCardsData = [
        ...state.intentCardsData.slice(0, action.orderIndex + 1),
        { ...action.payload, uuid: nanoid() },
        ...state.intentCardsData.slice(action.orderIndex + 1),
      ].map((card, index) => ({
        ...card,
        action_order: index + 1,
      }));
      return {
        ...state,
        intentCardsData: intentCardsData,
        savedCardsStatus: false,
      };
    }
    case actionTypes.REORDER_CARDS_R: {
      const { index, direction } = action;
      if (direction === 'down') {
        const newList = reorderList(state.intentCardsData, index, direction);
        return {
          ...state,
          intentCardsData: newList,
        };
      }
      const newList = reorderList(state.intentCardsData, index, direction);
      return {
        ...state,
        intentCardsData: newList,
      };
    }
    case actionTypes.REMOVE_CARD_R: {
      const i = action.index;
      const newList1 = [...state.intentCardsData];
      const removedIntentId = newList1[i].id;
      const previousRemovedIntent = state.removedIntents.find(
        (intent) => intent.id === removedIntentId
      );

      if (previousRemovedIntent) {
        return {
          ...state,
          intentCardsData: [...newList1.slice(0, i), ...newList1.slice(i + 1)],
          savedCardsStatus: false,
        };
      }

      const found = {
        id: newList1[i].id,
        action_type: newList1[i].action_type,
      };
      state.intentCardsData.forEach((item, index) => {
        if (index > i) {
          newList1[index].action_order -= 1;
        }
      });

      return {
        ...state,
        intentCardsData: [...newList1.slice(0, i), ...newList1.slice(i + 1)],
        removedIntents: [...state.removedIntents, found],
        savedCardsStatus: false,
      };
    }
    // REDUCERS FOR INDIVIDUAL CARDS
    case actionTypes.SET_CARD_PARAMS: {
      const { cardIndex, ...params } = action.payload ?? {};
      const updatedCard = {
        ...state.intentCardsData[cardIndex],
        ...params,
      };

      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, cardIndex),
          updatedCard,
          ...state.intentCardsData.slice(cardIndex + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.SET_HIDE_KEYBOARD: {
      const { textCardIndex, value } = action;
      const affectedTextCard = state.intentCardsData[textCardIndex];
      affectedTextCard.hide_keyboard = value;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, textCardIndex),
          affectedTextCard,
          ...state.intentCardsData.slice(textCardIndex + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.GOAL_CARD_ADD_DATA_R: {
      const { cardIndex, name, value } = action;
      const previousState = state.intentCardsData[cardIndex];
      const param1 = name ?? previousState.param1;
      const param2 = value ?? previousState.param2;
      const updatedGoalCard = {
        ...state.intentCardsData[cardIndex],
        param1,
        param2,
      };

      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, cardIndex),
          updatedGoalCard,
          ...state.intentCardsData.slice(cardIndex + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_BUTTON_R: {
      const found = state.intentCardsData[action.index];
      if (state.currentSlide === -1) {
        const buttons = found.intent_choices;
        const order =
          action.buttonOrder === -1
            ? found.intent_choices.length + 1
            : action.buttonOrder;
        const newObject = action.buttonObj;
        if (action.buttonOrder === -1) {
          newObject.choice_order = order;
          buttons.push(newObject);
        } else {
          newObject.choice_order = order + 1;
          const actionchoiceid = buttons[order].action_choice_id;
          buttons[order] = newObject;
          buttons[order].action_choice_id = actionchoiceid;
        }
        found.intent_choices = buttons;
        return {
          ...state,
          intentCardsData: [
            ...state.intentCardsData.slice(0, action.index),
            found,
            ...state.intentCardsData.slice(action.index + 1),
          ],
          savedCardsStatus: false,
        };
      }
      //Carousel Button Comes HERE
      const buttons =
        found.intent_carousel[state.currentSlide].h_choose_buttons;
      const order =
        action.buttonOrder === -1 ? buttons.length + 1 : action.buttonOrder;
      const newObject = action.buttonObj;
      newObject.h_choose_id =
        found.intent_carousel[state.currentSlide].h_choose_id;
      if (action.buttonOrder === -1) {
        newObject.h_button_order = order;
        buttons.push(newObject);
      } else {
        newObject.h_button_order = order + 1;
        const h_button_id = buttons[order].h_button_id;
        buttons[order] = newObject;
        buttons[order].h_button_id = h_button_id;
      }
      found.intent_carousel[state.currentSlide].h_choose_buttons = buttons;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.REMOVE_BUTTON_R: {
      const found1 = state.intentCardsData[action.index];
      const buttons1 = [
        ...found1.intent_choices.slice(0, action.buttonIndex),
        ...found1.intent_choices.slice(action.buttonIndex + 1),
      ];
      buttons1.forEach((btn, i) => {
        if (buttons1[i].choice_order > action.buttonIndex)
          buttons1[i].choice_order = buttons1[i].choice_order - 1;
      });
      found1.intent_choices = buttons1;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found1,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.REMOVE_CAROUSEL_BUTTON_R: {
      const found1 = state.intentCardsData[action.index];
      const found2 = found1.intent_carousel[action.slideIndex];
      const buttons1 = [
        ...found2.h_choose_buttons.slice(0, action.buttonIndex),
        ...found2.h_choose_buttons.slice(action.buttonIndex + 1),
      ];
      buttons1.forEach((btn, i) => {
        if (buttons1[i].h_button_order > action.buttonIndex)
          buttons1[i].h_button_order = buttons1[i].h_button_order - 1;
      });
      found2.h_choose_buttons = buttons1;
      found1.intent_carousel[action.slideIndex] = found2;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found1,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.REORDER_BUTTON_R: {
      const { cardIndex, fromIndex, toIndex } = action.payload;
      const card = { ...state.intentCardsData[cardIndex] };
      const result = Array.from(card.intent_choices);
      const [itemToMoved] = result.splice(fromIndex, 1);
      result.splice(toIndex, 0, itemToMoved);

      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, cardIndex),
          {
            ...card,
            intent_choices: result.map((item, index) => ({
              ...item,
              choice_order: index,
            })),
          },
          ...state.intentCardsData.slice(cardIndex + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.HANDLE_S3_UPLOAD_R: {
      const action_type = state.intentCardsData[action.index].action_type;
      const found = state.intentCardsData[action.index];
      switch (action_type) {
        case CardTypes.VIDEO:
        case CardTypes.IMAGE: {
          found.param1 = action.url;
          return {
            ...state,
            intentCardsData: [
              ...state.intentCardsData.slice(0, action.index),
              found,
              ...state.intentCardsData.slice(action.index + 1),
            ],
            saveAllCardsButtonState: true,
          };
        }
        case CardTypes.CAROUSEL: {
          const { url, index, slideIndex } = action;
          const found = state.intentCardsData[index];
          const modifiedObject = {
            ...found,
            intent_carousel: [
              ...found.intent_carousel.slice(0, slideIndex),
              { ...found.intent_carousel[slideIndex], image_url: url },
              ...found.intent_carousel.slice(slideIndex + 1),
            ],
          };
          return {
            ...state,
            intentCardsData: [
              ...state.intentCardsData.slice(0, index),
              modifiedObject,
              ...state.intentCardsData.slice(index + 1),
            ],
          };
        }
        case CardTypes.QUICK_REPLY: {
          let buttons = [...found.intent_choices];
          if (buttons.length > 0 && buttons[action.buttonOrder]) {
            buttons = [
              ...buttons.slice(0, action.buttonOrder),
              { ...buttons[action.buttonOrder], image_url: action.url },
              ...buttons.slice(action.buttonOrder + 1),
            ];
          }
          found.intent_choices = [...buttons];
          return {
            ...state,
            intentCardsData: [
              ...state.intentCardsData.slice(0, action.index),
              found,
              ...state.intentCardsData.slice(action.index + 1),
            ],
          };
        }
        default: {
          return {
            ...state,
          };
        }
      }
    }
    case actionTypes.SAVING_CONVERSATION_R: {
      return {
        ...state,
        conversationSaving: action.payload,
        savedCardsStatus: true,
      };
    }
    case actionTypes.UPDATE_API_CARD_DATA: {
      const found = {
        ...state.intentCardsData[action.index],
        ...action.payload,
      };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.UPDATE_API_CARD_ACTION: {
      const found = state.intentCardsData[action.index];
      found.api_action = action.payload;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_API_CARD_PARAM_R: {
      const found = state.intentCardsData[action.index];
      const newParam = {
        api_param_name: action.api_param_name,
        api_param_value: action.api_param_value,
      };
      if (action.order !== -1) found.api_params[action.order] = newParam;
      else found.api_params.push(newParam);
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.REMOVE_API_CARD_PARAM_R: {
      const found = state.intentCardsData[action.index];
      const params = found.api_params;
      const newParams = [
        ...params.slice(0, action.order),
        ...params.slice(action.order + 1),
      ];
      const newFound = { ...found, api_params: newParams };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          newFound,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_API_CARD_TEMPLATE_R: {
      const found = state.intentCardsData[action.index];
      const newParam = {
        template_data: action.template_data,
        template_url: '',
        template_location: action.template_location,
        template_usage_type: action.template_usage_type,
      };
      if (action.order !== -1) found.api_templates[action.order] = newParam;
      else found.api_templates.push(newParam);
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_API_CARD_ATTRIBUTE_R: {
      const found = {
        api_attributes: [],
        ...state.intentCardsData[action.index],
      };
      const newAttribute = action.payload;

      if (action.order !== -1) {
        found.api_attributes[action.order] = {
          ...found.api_attributes[action.order],
          ...newAttribute,
        };
      } else {
        found.api_attributes.push({
          intent_api_attr_id: 0,
          ...newAttribute,
        });
      }

      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.REMOVE_API_CARD_TEMPLATE_R: {
      const found = state.intentCardsData[action.index];
      const params = found.api_templates;
      const newParams = [
        ...params.slice(0, action.order),
        ...params.slice(action.order + 1),
      ];
      const newFound = { ...found, api_templates: newParams };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          newFound,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.REMOVE_API_CARD_ATTRIBUTE_R: {
      const found = state.intentCardsData[action.index];
      const params = found.api_attributes;
      const newAttributes = [
        ...params.slice(0, action.order),
        ...params.slice(action.order + 1),
      ];
      const newFound = { ...found, api_attributes: newAttributes };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          newFound,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.UPDATE_SET_ATTR_KEY_R: {
      const found = state.intentCardsData[action.index];
      found.param1 = action.param1;
      found.cust_attr_id = action.cust_attr_id;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.FETCH_BLOCKS_R: {
      return {
        ...state,
        allBlocks: action.payload,
      };
    }
    case actionTypes.FETCH_ATTRIBUTES_R: {
      const allAttributes = action.payload.map((item) => ({
        ...item,
        value: item.cust_attr_id,
        label: item.cust_attr_name,
      }));
      return {
        ...state,
        allAttributes,
      };
    }
    case actionTypes.ADD_GOTO_CARD_ATTRIBUTE_R: {
      const found = state.intentCardsData[action.index];
      found.cust_attr_id = action.cust_attr_id;
      found.param1 = action.param1;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_GOTO_CARD_CHOICE_R: {
      const found = state.intentCardsData[action.index];
      let index = -1;
      switch (action.choiceType) {
        case 'null': {
          const nullType = ChoiceTypes.NULL;
          index = found.intent_choices.findIndex(
            (item) => item.action_choice_id && item.choice_type === nullType
          );
          break;
        }
        case 'else': {
          const elseType = ChoiceTypes.ELSE;
          index = found.intent_choices.findIndex(
            (item) => item.action_choice_id && item.choice_type === elseType
          );
          break;
        }
        default:
          break;
      }

      if (index !== -1) {
        action.choice.action_choice_id =
          found.intent_choices[index].action_choice_id;
        found.intent_choices[index] = action.choice;
      } else found.intent_choices.push(action.choice);
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_GOTO_CARD_IF_CHOICE_R: {
      const found = state.intentCardsData[action.index];
      found.intent_choices = [...action.choices];

      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, action.index),
          found,
          ...state.intentCardsData.slice(action.index + 1),
        ],
        savedCardsStatus: false,
      };
    }

    case actionTypes.SET_CURRENT_INTENT_R: {
      return {
        ...state,
        currentAction: action.action,
        currentCardIndex: action.index,
        buttonOrder: action.buttonOrder,
        buttonModalShow: true,
        currentSlide: action.slideIndex,
      };
    }
    case actionTypes.CLOSE_ADD_BUTTON_MODAL_R: {
      return {
        ...state,
        buttonModalShow: false,
        currentSlide: -1,
        buttonOrder: -1,
      };
    }
    case actionTypes.REMOVE_CAROUSEL_SLIDE_IMAGE: {
      const { index, slideIndex } = action;
      const found = state.intentCardsData[index];
      const modifiedObject = {
        ...found,
        intent_carousel: [
          ...found.intent_carousel.slice(0, slideIndex),
          { ...found.intent_carousel[slideIndex], image_url: null },
          ...found.intent_carousel.slice(slideIndex + 1),
        ],
      };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          modifiedObject,
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_CAROUSEL_SLIDE_HEADING: {
      const { index, slideIndex, h_choose_title } = action;
      const found = state.intentCardsData[index];
      const modifiedObject = {
        ...found,
        intent_carousel: [
          ...found.intent_carousel.slice(0, slideIndex),
          { ...found.intent_carousel[slideIndex], h_choose_title },
          ...found.intent_carousel.slice(slideIndex + 1),
        ],
      };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          modifiedObject,
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_CAROUSEL_SLIDE_SUBHEADING: {
      const { index, slideIndex, h_choose_subtitle } = action;
      const found = state.intentCardsData[index];
      const modifiedObject = {
        ...found,
        intent_carousel: [
          ...found.intent_carousel.slice(0, slideIndex),
          { ...found.intent_carousel[slideIndex], h_choose_subtitle },
          ...found.intent_carousel.slice(slideIndex + 1),
        ],
      };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          modifiedObject,
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_CAROUSEL_SLIDE_URL: {
      const { index, slideIndex, click_url } = action;
      const found = state.intentCardsData[index];
      const modifiedObject = {
        ...found,
        intent_carousel: [
          ...found.intent_carousel.slice(0, slideIndex),
          { ...found.intent_carousel[slideIndex], click_url },
          ...found.intent_carousel.slice(slideIndex + 1),
        ],
      };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          modifiedObject,
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_CAROUSEL_SLIDE_CLICK_BLOCK: {
      const { index, slideIndex, click_intent_id } = action;
      const found = state.intentCardsData[index];
      const modifiedObject = {
        ...found,
        intent_carousel: [
          ...found.intent_carousel.slice(0, slideIndex),
          { ...found.intent_carousel[slideIndex], click_intent_id },
          ...found.intent_carousel.slice(slideIndex + 1),
        ],
      };
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          modifiedObject,
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.ADD_NEW_CAROUSEL_SLIDE: {
      const { index } = action;
      const found = state.intentCardsData[index];
      const carouselArrayLength = found.intent_carousel.length;
      const newCarouselObject = {
        h_choose_buttons: [],
        click_url: '',
        h_choose_id: null,
        image_url: '',
        h_choose_order: carouselArrayLength + 1,
        h_choose_title: '',
        h_choose_subtitle: '',
      };
      found.intent_carousel.push(newCarouselObject);
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          found,
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.REMOVE_CAROUSEL_SLIDE: {
      const { index, slideIndex } = action.payload;
      const carouselCard = state.intentCardsData[index];
      const slides = carouselCard.intent_carousel.filter(
        (_, i) => i !== slideIndex
      );

      if (slides.length === 0) {
        slides.push({
          h_choose_buttons: [],
          click_url: '',
          h_choose_id: null,
          image_url: '',
          h_choose_order: 1,
          h_choose_title: '',
          h_choose_subtitle: '',
        });
      }

      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          { ...carouselCard, intent_carousel: slides },
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.VALIDATE_TEXT_CARD: {
      const { index, text } = action;
      const found = state.intentCardsData[index];
      if (text.length) found.textValidation.status = true;
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          found,
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.UPDATE_SEQUENCE_DELAY_R: {
      const found = state.allIntents.find(
        (item) => item.id === action.intent_id
      );
      const index = state.allIntents.findIndex(
        (item) => item.id === action.intent_id
      );
      found.timeout_interval = action.time;
      return {
        ...state,
        allIntents: [
          ...state.allIntents.slice(0, index),
          found,
          ...state.allIntents.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.UPDATE_SEQUENCE: {
      const sequence = action.data;
      let allIntents = state.allIntents;
      const ind = allIntents.findIndex((item) => item.id === sequence.id);
      const seq = allIntents[ind];
      seq.intent_name = sequence.intent_name;
      seq.timeout_interval = sequence.timeout_interval;
      seq.poll_interval = sequence.poll_interval;
      allIntents = [
        ...allIntents.slice(0, ind),
        seq,
        ...allIntents.slice(ind + 1),
      ];
      return {
        ...state,
        allIntents,
      };
    }
    case actionTypes.GET_APIS_BY_AGENT:
      return {
        ...state,
        allAPIs: action.payload,
      };
    case actionTypes.UPDATE_AGENT: {
      const newAgents = state.allAgents.map((agent) => {
        return agent.id === action.payload.id ? action.payload : agent;
      });

      return {
        ...state,
        allAgents: newAgents,
      };
    }
    case actionTypes.DELETE_AGENT: {
      const newAgents = state.allAgents.filter(
        (agent) => agent.id !== action.payload
      );

      return {
        ...state,
        allAgents: newAgents,
      };
    }
    case actionTypes.UPDATE_SUBMISSION_CARD_STATE_R: {
      const { payload, index } = action;
      const currentCard = state.intentCardsData[index];
      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, index),
          { ...currentCard, ...payload },
          ...state.intentCardsData.slice(index + 1),
        ],
        savedCardsStatus: false,
      };
    }
    case actionTypes.SET_ATTRIBUTE_INTENT_CHOICE_R: {
      const { payload } = action;
      const currentCard = state.intentCardsData[payload.index];
      const currentChoice = currentCard.intent_choices[payload.choiceIndex];

      currentCard.intent_choices = [
        ...currentCard.intent_choices.slice(0, payload.choiceIndex),
        {
          ...currentChoice,
          bind_attr_name: payload.name,
          bind_attr_value: payload.name ? currentChoice.choice_title : null,
        },
        ...currentCard.intent_choices.slice(payload.choiceIndex + 1),
      ];

      return {
        ...state,
        intentCardsData: [
          ...state.intentCardsData.slice(0, payload.index),
          { ...currentCard },
          ...state.intentCardsData.slice(payload.index + 1),
        ],
      };
    }
    default:
      return state;
  }
};
