import './assets/css/botco-theme.css';
import './assets/css/ReactToastify.css';
import './App.css';

import { Box } from '@material-ui/core';
import { useAuth } from '@workos-inc/authkit-react';
import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { initFBSDK } from '~/utils';
import { queryClient } from '~/utils/queryClient';

import { Layout } from './components/Layout/Layout';
import { SpinnerLoader } from './components/SpinnerLoader';
import { SignIn } from './containers/Auth/SignIn';

export const App = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean | null>(
    null
  );
  const { user, isLoading, getAccessToken } = useAuth();

  React.useEffect(() => {
    const isLoggedIn = async () => {
      if (isLoading) return;

      try {
        const token = await getAccessToken();
        if (token) {
          localStorage.setItem('token', token);
          setIsUserLoggedIn(true);
          initFBSDK();
        }

        setIsUserLoggedIn(false);
      } catch (error) {
        setIsUserLoggedIn(false);
      }
    };

    isLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading, getAccessToken]);

  if (isLoading || isUserLoggedIn === null) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SpinnerLoader />
      </Box>
    );
  }

  if (!user) {
    return <SignIn />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Layout />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </QueryClientProvider>
  );
};
