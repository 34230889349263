import { Container, makeStyles } from '@material-ui/core';
import { ExitToAppOutlined as SignInIcon } from '@material-ui/icons';
import { useAuth } from '@workos-inc/authkit-react';
import * as React from 'react';

import { Button, Card, Typography } from '@botco/library';
import botcoLogo from '~/assets/img/botco.png';
import { Footer } from '~/containers/Conversations/Footer';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey['300'],
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    height: 50,
    width: 'fit-content',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(9),
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '6%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    maxWidth: 350,
    minWidth: 350,
  },
  cardBody: {
    padding: theme.spacing(2, 0, 4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export const SignIn: React.FC = () => {
  const classes = useStyles();
  const { signIn } = useAuth();

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="lg" className={classes.content}>
        <img src={botcoLogo} alt="logo" className={classes.logo} />
        <div className={classes.formContainer}>
          <div className={classes.titleContainer}>
            <Typography variant="h3" color="primary">
              Hello, Welcome to Botco.ai
            </Typography>
            <Typography variant="h4">
              Sign in to begin creating a bot using Botco.ai.
            </Typography>
          </div>
          <Card className={classes.card}>
            <Typography color="primary" variant="h3" align="center">
              SIGN IN
            </Typography>
            <div className={classes.cardBody}>
              <Typography color="black" align="center">
                Click the button below to sign in to your account.
              </Typography>
            </div>
            <Button
              fullWidth
              startIcon={<SignInIcon />}
              onClick={() => signIn()}
            >
              Sign in
            </Button>
          </Card>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
