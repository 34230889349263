import './index.css';

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { ThemeProvider, StylesProvider, CssBaseline } from '@material-ui/core';
import { AuthKitProvider } from '@workos-inc/authkit-react';
import LogRocket from 'logrocket';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { theme } from '@botco/library';

import { App } from './App';
import { constants } from './config';
import { LOG_ROCKET_KEY } from './constants';
import { store } from './redux/store';

const environment = constants.app_env || 'dev';

if (environment === 'production') {
  LogRocket.init(LOG_ROCKET_KEY);

  LogRocket.getSessionURL((sessionURL) => {
    Honeybadger.beforeNotify((notice) => {
      if (!notice) return;
      notice.context.LogRocketSessionURL = sessionURL;
    });
  });
}

if (import.meta.env.VITE_ENABLE_MSW_WORKER === 'true') {
  // code splitting
  import('./mocks/worker').then(({ worker }) => {
    // eslint-disable-next-line no-console
    console.info('starting worker');
    worker.start({ waitUntilReady: true });
  });
}

const ErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => {
  if (import.meta.env.DEV) {
    return <>{children}</>;
  }

  const honeybadger = Honeybadger.configure({
    apiKey: '82819390',
    revision: import.meta.env.VITE_HONEYBADGER_REVISION || 'main',
    environment,
  });

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      {children}
    </HoneybadgerErrorBoundary>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <AuthKitProvider
    devMode={constants.app_env === 'dev'}
    clientId={import.meta.env.VITE_WORKOS_CLIENT_ID}
    apiHostname="auth.botco.ai"
    onRefresh={({ accessToken }) => localStorage.setItem('token', accessToken)}
    onRefreshFailure={() => {
      window.location.reload();
      localStorage.removeItem('token');
    }}
  >
    <ErrorBoundary>
      <Provider store={store}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StylesProvider>
      </Provider>
    </ErrorBoundary>
  </AuthKitProvider>
);
