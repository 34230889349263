import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { clsx } from 'clsx';

import { DEFAULT_ATTRIBUTE, CUSTOM_ATTRIBUTE } from '~/constants/attributes';

import { AttributesListProps } from './AttributeList.types';
import { useAttributeList } from './useAttributeList';
import { AttributeChip } from '../AttributeChip';
import { AttributeGroup } from '../AttributeGroup';
import { AttributeOption } from '../AttributeOption';

export const AttributesList = ({
  agentId,
  anchorEl,
  onClose,
  onSelect,
  filter,
  enableHover,
  isInvalidAttributeName,
  disableCreate,
  additionalAttributes,
}: AttributesListProps) => {
  const {
    isOpen,
    onCloseMenu,
    customAttributes,
    defaultAttributes,
    hoveredIndex,
    indexOffset,
    onSelectItem,
    isReserved,
  } = useAttributeList({
    agentId,
    anchorEl,
    filter,
    onClose,
    onSelect,
    disableCreate,
    additionalAttributes,
  });

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      elevation={2}
      onClose={onCloseMenu}
      disableEnforceFocus
      disableAutoFocus
      autoFocus={false}
      getContentAnchorEl={null}
      data-testid="attributes-list"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      MenuListProps={{
        style: {
          minWidth: 250,
          width: 'min-content',
        },
      }}
      PaperProps={{
        style: {
          maxHeight: 400,
        },
      }}
    >
      {customAttributes.length + defaultAttributes.length === 0 &&
        !indexOffset && (
          <MenuItem
            data-testid="create-attribute-option"
            style={{ width: '100%' }}
            disabled={isReserved}
          >
            Attribute
            <AttributeChip attribute={filter ?? ''} />
            is not available.
          </MenuItem>
        )}
      {indexOffset === 1 && (
        <MenuItem
          data-testid="create-attribute-option"
          style={{ width: '100%' }}
          disabled={isReserved}
          onClick={onSelectItem({
            label: filter ?? '',
          })}
          className={clsx({
            'Mui-selected': hoveredIndex === 0 && enableHover && !isReserved,
          })}
        >
          <AttributeOption
            label={filter ?? ''}
            showInvalidAttributeMessage={isInvalidAttributeName}
          />
        </MenuItem>
      )}
      {defaultAttributes?.length > 0 && (
        <AttributeGroup key="default-attribute-group" group={DEFAULT_ATTRIBUTE}>
          {defaultAttributes.map((option, index) => (
            <MenuItem
              data-testid={`attributes-list-option-${option.label}`}
              key={`${option.label}-${option.value}-${index}`}
              data-index={index}
              onClick={onSelectItem(option)}
              className={clsx({
                'Mui-selected':
                  hoveredIndex === index + indexOffset && enableHover,
              })}
            >
              <AttributeOption {...option} />
            </MenuItem>
          ))}
        </AttributeGroup>
      )}
      {customAttributes.length > 0 && (
        <AttributeGroup key="custom-attribute-group" group={CUSTOM_ATTRIBUTE}>
          {customAttributes.map((option, index) => (
            <MenuItem
              key={`${option.label}-${option.value}-${index}`}
              data-index={index + defaultAttributes.length}
              onClick={onSelectItem(option)}
              data-testid={`attributes-list-option-${option.label}`}
              className={clsx({
                'Mui-selected':
                  hoveredIndex ===
                    index + indexOffset + defaultAttributes.length &&
                  enableHover,
              })}
            >
              <AttributeOption {...option} />
            </MenuItem>
          ))}
        </AttributeGroup>
      )}
    </Menu>
  );
};
