import 'react-image-crop/dist/ReactCrop.css';

import {
  Avatar,
  IconButton,
  Dialog,
  DialogContent,
  Box,
  CircularProgress,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { base64ToBlob } from 'base64-blob';
import { Component } from 'react';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';

import { BotcoAPI } from '@botco/api';
import { Button, Typography } from '@botco/library';
import { constants } from '~/config';
import { updateAgent } from '~/redux/actions/conversationDataR';

const newApiCall = new BotcoAPI(constants.api_url);

class EditImageModal extends Component {
  state = {
    conversation_title: '',
    avatar: '',
    noavtar: true,
    avatarImg: '',
    avatarPreview: '',
    croppedImage: '',
    showSpinner: false,
    saveBtnDisable: true,
    croppedImageUrl: '',
    crop: {
      unit: 'px',
      width: 200,
      height: 200,
      maxWidth: 500,
      aspect: 1 / 1,
    },
  };

  uploadAvatar = async () => {
    const body = new FormData();
    const avatarBlob = await base64ToBlob(this.state.croppedImageUrl);
    body.append('file', avatarBlob, this.state.selectedFile?.name);
    const response = await newApiCall.uploadFile(body);
    const { location } = response.data;
    return location;
  };

  editConversationAvatar = async (event) => {
    const { agentDetails, updateAgent, closed } = this.props;
    event.preventDefault();
    this.setState({
      showSpinner: true,
      saveBtnDisable: true,
    });

    const location = await this.uploadAvatar();
    updateAgent({
      ...agentDetails,
      avatar: location,
    });

    document.getElementById('myInput').value = '';
    this.setState({
      showSpinner: false,
      croppedImageUrl: '',
      avatarPreview: '',
      croppedImage: '',
    });
    this.removeImage(event);
    closed();
  };

  removeImage = (event) => {
    event.preventDefault();
    this.setState({
      avatarPreview: '',
      croppedImage: '',
      saveBtnDisable: true,
    });
  };

  onSelectFile = (e) => {
    this.setState({
      crop: {
        unit: 'px',
        width: 200,
        height: 200,
        maxWidth: 500,
        aspect: 1 / 1,
      },
    });
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      (e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'image/bmp')
    ) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          avatarPreview: reader.result,
          selectedFile: e.target.files[0],
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      alert('Invalid format');
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    if (crop.width >= 200 && crop.width <= 500) this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl, saveBtnDisable: false });
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  closeCreateConvModal = () => {
    document.getElementById('myInput').value = '';
    this.setState(
      {
        croppedImageUrl: '',
        avatarPreview: '',
        saveBtnDisable: true,
        selectedFile: undefined,
      },
      () => this.props.closed()
    );
  };

  render() {
    const avatarSrc = this.props.avatarImage;
    const { crop } = this.state;
    return (
      <Dialog fullWidth maxWidth="sm" open={this.props.openEditImage}>
        <DialogContent>
          <Typography
            align="center"
            variant="h6"
            fontWeight={600}
            color="primary"
          >
            Edit Chatbot Image
          </Typography>
          <form
            onSubmit={this.editConversationAvatar}
            encType="multipart/form-data"
          >
            <input
              id="myInput"
              type="file"
              accept="image/*"
              ref={(ref) => (this.myInput = ref)}
              style={{ display: 'none' }}
              onChange={this.onSelectFile}
            />
            <Box
              display="flex"
              alignItems="center"
              my="16px"
              style={{ gap: 16 }}
            >
              <Avatar src={avatarSrc} />

              <IconButton size="small" onClick={() => this.myInput.click()}>
                <PublishIcon fontSize="large" color="primary" />
              </IconButton>
              {this.state.avatarPreview == '' && (
                <Typography variant="caption" color="textSecondary">
                  (Images should be 200px x 200px,ideally uploaded as a square.)
                </Typography>
              )}
            </Box>
            {this.state.avatarPreview !== '' && (
              <ReactCrop
                src={this.state.avatarPreview}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            <Box
              display="flex"
              justifyContent="center"
              mt={2}
              style={{ gap: 16 }}
            >
              <Button
                variant="outlined"
                onClick={() => this.closeCreateConvModal()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={this.editConversationAvatar}
                disabled={this.state.saveBtnDisable || this.state.showSpinner}
              >
                {this.state.showSpinner ? (
                  <CircularProgress size={16} />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapActionsToProps = {
  updateAgent,
};

// eslint-disable-next-line import/no-default-export
export default connect(null, mapActionsToProps)(EditImageModal);
