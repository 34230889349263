import { Box, createStyles, List, makeStyles } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import GroupIcon from '@material-ui/icons/PeopleOutline';
import { useAuth } from '@workos-inc/authkit-react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import BlocksIcon from '~/assets/icons/side-nav/blocks.svg?react';
import BookOpen from '~/assets/icons/side-nav/book-open.svg?react';
import Logo from '~/assets/icons/side-nav/botco-logo-square.svg?react';
import BroadcastIcon from '~/assets/icons/side-nav/broadcast.svg?react';
import ChatbotIcon from '~/assets/icons/side-nav/chatbots.svg?react';
import ContactsIcon from '~/assets/icons/side-nav/contacts.svg?react';
import DashboardIcon from '~/assets/icons/side-nav/dashboard.svg?react';
import LaunchIcon from '~/assets/icons/side-nav/launch.svg?react';
import LogOutIcon from '~/assets/icons/side-nav/sign-out.svg?react';
import TrainingIcon from '~/assets/icons/side-nav/training.svg?react';
import { routes } from '~/constants/routes';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { resetReduxStore } from '~/redux/actions/conversationPreview';
import { removeTokens } from '~/utils';

import { InstaStackSideNavItem } from './components/InstaStackSideNavItem';
import { SideNavItem } from './SideNavItem';

export const SideNav = () => {
  const { selectedAgent } = useSelectedChatbot();
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useCurrentUser();
  const canAccessAuthoring =
    user.isAdmin || user.isBotAuthor || user.hasDeployRole;
  const canAccessContact = user.isAdmin || user.hasPIIRole;
  const canAccessBroadcast = user.isAdmin || user.hasPIIRole;
  const canAccessTraining = user.isAdmin || user.hasPIIRole;
  const canAccessDeployment = user.isAdmin || user.hasDeployRole;
  const canAccessInstaStack = user.isAdmin || user.hasInstaStackRole;
  const canAccessDashboard =
    user.isAdmin || user.isBotAuthor || user.hasPIIRole || user.hasDeployRole;

  const { signOut, signIn } = useAuth();

  const isBotcoUser = Boolean(user?.email?.includes('@botco.ai'));

  const handleLogout = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    signOut();
    removeTokens();
    window.sessionStorage.clear();
    dispatch(resetReduxStore());
    window.location.href = '/';
  };

  return (
    <Box component="nav" className={classes.nav} data-testid="app-side-nav">
      <List className={classes.list}>
        <Logo />
        {canAccessDashboard && (
          <SideNavItem
            title={routes.dashboard.title}
            to={routes.dashboard.get(selectedAgent?.id!)}
            data-testid="side-nav-dashboard"
          >
            <DashboardIcon />
          </SideNavItem>
        )}
        {canAccessAuthoring && (
          <SideNavItem
            title={routes.chatbots.title}
            to={routes.chatbots.get()}
            data-testid="side-nav-chatbot-list"
          >
            <ChatbotIcon />
          </SideNavItem>
        )}
        {canAccessContact && (
          <SideNavItem
            title={routes.contacts.title}
            to={routes.contacts.get(selectedAgent?.id!)}
            data-testid="side-nav-contacts"
          >
            <ContactsIcon />
          </SideNavItem>
        )}
        {canAccessBroadcast && (
          <SideNavItem
            title={routes.broadcast.title}
            to={routes.broadcast.get(selectedAgent?.id!)}
            data-testid="side-nav-broadcast"
          >
            <BroadcastIcon />
          </SideNavItem>
        )}
        {canAccessTraining && (
          <SideNavItem
            title={routes.training.title}
            to={routes.training.get(selectedAgent?.id!)}
            data-testid="side-nav-training"
          >
            <TrainingIcon />
          </SideNavItem>
        )}
        {canAccessInstaStack && <InstaStackSideNavItem />}
        {user.isAdmin && (
          <SideNavItem
            title={routes.blocks.title}
            data-testid="side-nav-blocks"
            to={{
              pathname: routes.blocks.get(selectedAgent?.id!),
            }}
          >
            <BlocksIcon />
          </SideNavItem>
        )}
        {canAccessDeployment && (
          <SideNavItem
            title={routes.deployments.title}
            to={routes.deployments.get()}
            data-testid="side-nav-lauch"
          >
            <LaunchIcon />
          </SideNavItem>
        )}
      </List>
      <List className={classes.list}>
        {isBotcoUser && (
          <SideNavItem
            title="Switch Organization"
            component="li"
            data-testid="side-nav-switch-organizations"
            onClick={() => signIn({ organizationId: 'invalid' })}
          >
            <CachedIcon />
          </SideNavItem>
        )}
        {user.isAdmin && (
          <SideNavItem
            title={routes.users.title}
            to={routes.users.get()}
            data-testid="side-nav-collaborators"
          >
            <GroupIcon />
          </SideNavItem>
        )}
        <SideNavItem
          title={routes.helpCenter.title}
          to={routes.helpCenter.get()}
          data-testid="side-nav-help-center"
        >
          <BookOpen />
        </SideNavItem>
        <SideNavItem
          title="Sign-Out"
          component="li"
          onClick={handleLogout}
          data-testid="side-nav-logout"
        >
          <LogOutIcon />
        </SideNavItem>
      </List>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    nav: {
      backgroundColor: (theme.palette as any).purple.main,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      padding: '12px 0 12px 12px',
      width: theme.spacing(7.5),
      minWidth: theme.spacing(7.5),
    },
    list: {
      padding: 0,
    },
  })
);
