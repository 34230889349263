import { axiosClient } from '../axiosClient';
import { User } from './types';

type Options = {
  body: FormData;
};

export const updateMyAvatar = ({ body }: Options) => {
  return axiosClient.put<User>('/me/avatar', body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};
